@import '../../styles/variables';
@import '../../styles/mixin';

.filters {
  padding-top: 32px;

  @include media(lg) {
    padding: 32px 0 54px;
    height: 100%;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &_desktop {
    height: 100%;
    display: none;
    line-height: 24px;

    @include media(lg) {
      display: flex;
      flex-direction: column;
      min-width: 260px;
      row-gap: 39px;
    }

    &__menu {
      overflow: unset;
      position: sticky;
      top: 156px;

      p {
        font-weight: 600;
        margin-bottom: 12px;
      }
    }

    .content {
      height: auto;
      max-height: none !important;
      overflow-y: none !important;

      &__button {
        display: flex;
        align-items: center;
        text-transform: uppercase;
        font-size: $text-base;
        font-weight: 500;
        transition: $transition-all;
        padding: 22px 30px;
        color: $primary;
        margin-top: 12px;
        width: 100%;

        &_disabled {
          display: none;
        }

        &:focus:not(:focus-visible) {
          outline: 0;
        }
      }
    }
  }

  &_mobile {
    position: relative;
    width: 100%;

    @media screen and (min-width: 425px) {
      width: 47%;
    }

    @include media(sm) {
      width: 48%;
    }

    @include media(md) {
      width: 49%;
    }

    @include media(lg) {
      display: none;
    }

    &__button {
      width: 100%;
      padding: 8px 12px 8px 16px;
      border: 1px solid $gray-200;

      &__icon {
        border-left: 1px solid $gray-100;
        color: $primary-400;
        font-size: $text-lg;
        font-weight: 600 !important;
        padding-left: 10px;
      }

      div {
        display: flex !important;
        align-items: center;
        justify-content: space-between !important;

        p {
          font-size: $text-base;
        }
      }
    }

    &__menu {
      display: flex;
      flex-direction: column;
      padding: 25px 20px;
      background: $white;
      border-radius: $border-radius;
      box-shadow: $box-shadow-2;
      min-width: 290px;
      position: absolute;
      top: 45px;
      width: 100%;
      z-index: 3;
    }
  }

  .content {
    position: relative;
    display: flex;
    overflow-y: auto;
    flex-direction: column;
    margin-right: -15px;
    padding-right: 15px;
    max-height: 532px;
    row-gap: 12px;

    .filters__links {
      border: 1px solid $gray-200;
      border-radius: 24px;
      line-height: 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 24px;

      .text_underline {
        background-position: center bottom;
        background-repeat: repeat-x;
        background-size: 4px 1px;
        background-image: linear-gradient(
          to right,
          rgb(135, 135, 135) 50%,
          transparent 50%
        );
      }

      .count {
        display: inline;
        line-height: 16px;
        height: 20px;
        background: $blog-filter-bage;
        border-radius: $border-radius-badge;
        color: $blog-filter-bage-color;
        font-size: $text-xs;
        padding: 2px 8px;
        margin-left: 10px;
      }
    }
  }
}
