@import '../../../styles/variables';
@import '../../../styles/mixin';

.filters_modal {
  $space-x: 40px;
  $form-width: 446px;
  background: $background-filters-treez-modal !important;
  max-height: 82% !important;

  @include media(sm) {
    position: fixed;
    margin-top: 0 !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    box-shadow: $box-shadow;
    z-index: 20;
    top:0;
    left: -62px !important;
    max-height: 100% !important;
    width: 100%;
    max-width: $form-width;
  }

  > div {
    flex-grow: 1;
    display: flex;
    background-color: inherit;
    flex-direction: column;
    height: 100%;
    max-width: 560px;
    width: 100%;
    margin: 0 auto;
    cursor: pointer !important;

    @media screen and (max-width: 420px) {
      padding: 2rem;

      > span {
        top: -1.125rem;
      }
    }

    @include media(sm) {
      position: relative;
      left: 62px;
      margin: 0 !important;
      max-height: 100%;
      max-width: 384px;

      > span {
        display: none;
      }
    }
  }

  .filters_header {
    display: flex;
    background-color: inherit;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.25rem;

    .filters_header__title {
      font-size: $text-xl;
      color: $filter-title-section;
      line-height: 2rem;
      font-weight: 500;
      letter-spacing: 0.015rem;

      @include media(sm){
        font-size: $text-2xl;
      }
    }

    .filters_header__actions {
      display: flex;
      align-items: center;
      gap: 0.75rem;

      .filters_header__clear {
        font-size: $text-sm;
        font-weight: 700;
        padding: 0;
        height: 22px;
        border-radius: 0;
        border-bottom: 2px solid $gray-600;
        //text-decoration: underline;
        display: none;

        @include media(sm) {
          display: block;
        }

      }
      
    }
  }

  .filters_body {
    display: flex;
    background-color: inherit;
    flex-direction: column;
    height: calc(100vh - 132px); 
   
    @media screen and (max-width: 640px) {
      flex-grow: 1;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: transparent;
        border-radius: 8px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $gray-200;
      }
    }
  }

  .filters_list__wrapper {
    margin-top: 1rem;
    display: flex;
    flex-direction: column; 
    flex-grow: 1;
    padding-right: 10px;
    background-color: $background-filters-treez-modal !important;

    @include media(sm){
      overflow-y: auto;
      
      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: transparent;
        border-radius: 8px;
      }

      &:hover {
        &::-webkit-scrollbar-thumb {
          background-color: $gray-200;
        }
      }
    }

    > div {
      padding: 0 !important;
    }
    
  }
 
  .discounts {
    background-color: $background-filters-treez-modal !important;
    z-index: 22;
  }

  .filters_footer {
    position:sticky;
    bottom: 2.5rem;
    display: flex;
    width: 100%;
    background: inherit;
    border-top: 1px solid $gray-100;
    gap: 1rem;
    padding-top: 1.25rem;
    cursor: pointer;

    @media screen and (max-width: 420px) {
      bottom: 2rem;
    }

    button {
      text-transform: uppercase;
      max-height: 48px;
      flex: 1;
    }

    @include media(sm) {
       display: none;
    }
  }
}

.hidden {
  visibility: hidden;
  height: 0;
}

.cart_nav__overlap {
    background: $overlay-bg;
    position: fixed;
    inset: 0;
    z-index: 19;
    transition: $transition-all;
    pointer-events: none;
    visibility: hidden;
    opacity: 0;

    &_open {
      cursor:  pointer !important;
      visibility: visible !important;
      opacity: 1 !important;
      pointer-events: initial;
    }
}