@import '../../styles/variables';
@import '../../styles/mixin';

.main-header {
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  height: auto;
  background: $background-header;
  border-bottom: $header-weight-border-bottom solid $header-border-bottom !important;
  background-image: $header-background-image;
  backdrop-filter: $backdrop-filter;
  background-position: 50%;
  background-repeat: no-repeat !important; 
  background-size: cover !important;
  box-shadow: $header-shadow;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  user-select: none;
  z-index: 10;

  @include media(lg) {
    padding-top: 14px;
    padding-bottom: 14px;
    border-bottom: none;
  }

  .container__logo {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    figure {
      position: relative;

      img {
        width: $header-logo-max-width-mobile;
        max-height: $header-logo-fix-height-mobile;

        @include media(lg){
          width: $header-logo-max-width;
          max-height: $header-logo-fix-height;
        }
      }

    }
  }
}
