@import '../../styles/variables';
@import '../../styles/mixin';

.dualtext__section {
  display: flex;
  align-items: center;

  .container {
    display: flex;
    flex-direction: column;
    padding-left: 32px;
    padding-right: 32px;
    row-gap: 20px;

    @include media(sm) {
      column-gap: 30px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}


