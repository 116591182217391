@import '../../../styles/variables';
@import '../../../styles/mixin';

.filters {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 15px;

  label {
    color: $gray-600;
    font-size: $text-base;
    height: 24px !important;

    .count {
      background: $filter-algolia-bage;
      border-radius: 20px;
      font-size: $text-xs;
      margin-left: 8px;
      padding: 2px 8px;
    }
  }
}
