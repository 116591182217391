@import '../../styles/variables';
@import '../../styles/mixin';

.fav__actions {
  position: relative;
  display: flex;
  gap: 0.5rem;
  align-items: center;

  svg {
    height: 24px;
    width: 24px;

    @media screen and (max-width: 400px) {
      width: 24px;
      height: 24px;
    }

    * {
      stroke: $header-action-icon-color;
    }
  }

  .fav__actions_label {
    text-transform: uppercase;
    color: $header-action-icon-color;
  }

  .counter {
    background: $badge-counter;
    border-radius: 50%;
    color: $badge-counter-text;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: $text-xs;
    height: 24px;
    width: 24px;
    line-height: 12px;
    right: -12px;
    top: -10px;
  }
}
