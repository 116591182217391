@import '../../../styles/variables';
@import '../../../styles/mixin';

.numeric_menu {
  .numeric_menu_item {
    padding: 0.35rem 0;

    .numeric_menu_label {
      display: flex;
      align-items: center;
      cursor: pointer;

      .numeric_menu_radio {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: $input-bg;
        border: 1.5px solid $input-ckek-border-color;
        border-radius: 50%;
        height: 20px;
        margin: 0;
        outline: 0;
        position: relative;
        width: 20px;
        cursor: pointer;

        &::before {
          content: '';
          width: 10px;
          height: 10px;
          display: none;
          border-radius: 50%;
          transform: translate(-50%, -50%);
          top: 50%;
          left: 50%;
          position: absolute;
          cursor: pointer;
        }

        &:checked {
          border-color: $input-check-color;
          &::before {
            background-color: $input-check-color;
            display: inline-block;
          }
        }
      }

      span {
        margin-left: 0.5rem;
        text-transform: capitalize;
        color: $gray-600;
        font-size: $text-base;
        line-height: 24px;
      }
    }
  }
}
