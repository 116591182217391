@use 'sass:math';
@import '../../styles/variables';
@import '../../styles/mixin';

.section {
  display: flex;
  column-gap: 12px;
  align-items: center;
  position: sticky;
  top: 10.6rem;
  gap: 8px;
  transform: none;
  will-change: position;
  background: inherit;
  z-index: 5;

  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  flex-wrap: wrap;
  //border-bottom: 1px solid $gray-100;

  @include media(sm) {
    top: 7.19rem;
    flex-wrap: nowrap;
  }

  @include media(md) {
    top: 8.38rem;
  }

  @include media(lg) {
    padding-top: 1rem;
    padding-bottom: 1rem;
    top: 9.47rem;
    gap: 16px;
  }

  .slider_container {
    order: 3;
    display: none;
    flex-grow: 1;
    min-width: 300px;
    overflow-x: scroll;
    scroll-behavior: smooth;
    -ms-overflow-style: none;
    scrollbar-width: none;

    @include media(sm){
      display: flex;
      align-items: center;
      gap: 8px;
    }

    @include media(sm) {
      order: 2;
    }

    @include media(md) {
      gap: 16px;
    }
  }

  .slider_container::-webkit-scrollbar {
    display: none;
  }

  .slider_container:after {
    content: '';
    position: sticky;
    top: 0;
    right: 0;
    display: block;
    background: linear-gradient(270deg, $background-page, transparent);
    flex: 0 0 16px;
  }

  &__select {
    &_button {
      display: flex;
      flex-direction: row;
      align-items: center;
      border-radius:$filter-btn-border-radius;
      padding: 8px 14px !important;
      height: 40px !important;
      gap: 10px;
      border: 1px solid $filter-btn-border;
      color: $filter-btn-text-color;
      background: $filter-btn-background;
      transition: all 0.3s ease;

      > div {
        font-weight: normal !important;
        gap: 10px !important;
      }
      
      @include media(lg){
        height: 48px !important;
        padding: 12px 16px !important;
      }

      svg {
        * {
          stroke: $filter-btn-icon-color;
        }
      }

      & > svg {
        transition: all 0.3s ease;
        transform: rotate(0deg);
      }

      &_open, &_active {
        background: $filter-active-btn-background;
        color: $filter-active-btn-text-color;
        border: 1px solid $filter-active-btn-border;

        svg {
          * {
            stroke: $filter-active-btn-icon-color;
          }
        }

        & > svg {
          transition: all 0.3s ease;
          transform: rotate(90deg);
        }
      }

      &_label {
        display: flex;
        align-items: center;
        gap: 6px;
      }
    }

    &_panel {
      width: 320px;
      max-height: 472px;
      border-radius: $border-radius;
      padding: 16px;
      background-color: $background-page;
      box-shadow: 0px 6px 12px 0px #00000033;
      z-index: 99999;

      @media screen and (max-width: 420px) {
        width: 300px;
        max-height: 350px;

        [class*='accordion'] {
          max-height: 250px;
        }
      }

      [class*='accordion_header'] {
        display: none;
      }

      [class*='accordion'] {
        min-height: 150px;
      }

      .button_row {
        display: flex;
        width: 100%;
        justify-content: space-between;

        .panel_button {
          height: 48px;

          div {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 12px;
            gap: 8px;

            span {
              line-height: 16px;
            }
          }
        }
      }
    }
  }

  .filters_header {
    background: inherit;
    display: none;
    width: 225px;

    @include media(md) {
      display: flex;
      align-items: center;
    }

    @include media(lg) {
      width: 260px;
    }

    .filters_header__title {
      font-size: 1.25rem;
      line-height: 1.5rem;
      font-weight: 500;
      min-width: 40px;
      color: $gray-500;
    }
  }

  .filter_menu__button {
    order: 1;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    padding: 8px 14px;
    gap: 10px;
    height: 40px;
    border-radius: $filter-btn-border-radius;
    background: $filter-btn-background;
    color: $filter-btn-text-color !important;
    border: 1px solid $filter-btn-border;
    font-weight: 500;
    cursor: pointer;

    @include media(lg) {
      height: 48px;
      padding: 12px 16px;
    }

    svg {
      * {
        stroke: $filter-toggle-icon-color !important;
      }
    }

    &_active {
      background: $filter-active-btn-background;
      color: $filter-active-btn-text-color !important;
      border: 1px solid $filter-active-btn-border;

      svg {
        * {
          stroke: $filter-active-toggle-icon-color !important;
        }
      }
    }

  }

  .filters_count {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: $filter-section-button-counter-border-radius;
    background-color: $filter-section-button-counter-background-color;
    font-weight: bold;
    font-size: $text-sm;
    line-height: 16px;
    width: 22px;
    height: 22px;
    color: $filter-section-button-counter-text-color;
    //padding: 3px 11px 2px 11px;
  }

  div.ais-NumericMenu {
    margin-left: 10px;
  }

  select {
    font-weight: 400;
    font-size: $text-base;
  }

  .filters_select_section {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @include media(md) {
      width: 490px;
    }

    .filters_select_section__count {
      color: $gray-300;
      font-weight: 500;
      display: none;

      @include media(lg) {
        display: block;
      }
    }

    .filters_select_section__right {
      display: flex;
      position: relative;
    }

    /*   .hits_per_page_select {
      width: 160px;
      display: none;
      margin-left: 1rem;

      @include media(md) {
        width: 200px;
        display: block;
      }

      button {
        height: 42px;
        text-transform: none;

        span {
          color: $secondary-400;
          font-size: $text-base;
        }

        i {
          font-size: $text-2xl;
          color: $secondary-400;
        }
      }
    }

    .hits_per_page_select__menu {
      font-size: $text-base;
      color: $gray-500;
    } */
  }

  .clear-button {
    font-weight: 500;
    font-size: $text-base;
    line-height: 24px;
    color: $label-btn-link;
    padding: 0;

    i {
      align-self: center;
      font-size: $text-2xl;
      margin-right: 12px;
    }
  }

  .title {
    display: flex;
    align-items: center;
    gap: 6px;
    font-weight: 400;
    font-size: $text-base;
  }
}

.sort_by_button {
  order: 2;
  display: flex;
  align-items: center;
  line-height: 20px;
  height: 40px;
  border-radius: $border-radius-btn;
  min-width: 60px;
  border: none;
  flex-shrink: 1;
  width: 60px;
  flex-grow: 1;

  @media screen and (min-width: 450px) {
    max-width: 220px;
    min-width: 220px;
    margin-left: auto;
  } 

  @include media(sm) {
    min-width: 135px;
    margin-left: 0;
  }

  @include media(md){
    order: 3;
  }

  @include media(lg){
    max-width: 220px;
    height: 48px;
  }

  .sort_by_select {
    position: relative;
    display: flex;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-repeat: no-repeat;
    background-size: 10px, auto;
    cursor: pointer;

    @include media(md) {
      flex-grow: 2;
    }

    button {
      line-height: 20px;
      padding: 8px 14px;
      background: $sort-by-button-background-color;
      border: 1px solid $sort-by-button-border-color;
      border-radius: $border-radius-btn;
      height: 40px;

      &:hover, &:focus {
        border: 1px solid $sort-by-button-border-color;
      }

      @include media(md) {
        font-size: $text-base;
        padding: 8px 16px;
      }

      @include media(lg){
        height: 48px;  
      }

      i {
        font-size: $text-3xl;
        font-weight: 700;
        color: $sort-by-icon !important;
      }

      .sort_by_select_label {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        font-size: 13px;
        line-height: 15px;
        color: $gray-700 !important;

        @include media(lg){
          font-size: $text-sm;
          line-height: 18px;
        }
      }

      .sort_by_placeholder_label {
        font-size: 12px;
        line-height: 12px;
        color: $sort-by-button-label-color;
      }

      .sort_by_default_selected_label {
        font-weight: 400;
        font-size: $text-base;
        color: $sort-by-button-text-color !important;
      }
    }

    .sort_by_select_menu {
      padding-right: 1px;
    }
  }
}

.filters_list {
  background: inherit;
  display: flex;
  flex-direction: column;
  padding: 0 0 12px;

  @include media(sm) {
    padding: 0 24px 12px;
  }

  @include media(md) {
    padding: 0;
    /* overflow-y: scroll;
    padding-right: 0.5rem; */
  }

  section:first-child {
    padding-top: 0 !important;
  }

  &::-webkit-scrollbar {
    width: 4px;
  }

  /* &::-webkit-scrollbar-thumb {
    background-color: $gray-200;
    border-radius: 8px;
  } */

  .refinement_list {
    text-transform: capitalize;
  }

  .slider_container {
    padding: 0 20px;
    margin-bottom: 1.5rem;
  }

  .specials_header {
    display: flex;
    align-items: center;

    .scpecials_header__badge {
      padding: 0.125rem 0.5rem;
      margin-bottom: 0.125rem;
    }
  }

  .filters__header_section {
    &-specials {
      display: flex;
      flex-direction: column;
      row-gap: 5px;
    }

    span {
      font-weight: 500;
      font-size: $text-base !important;
      color: $filter-algolia-title !important;

      + span {
        font-weight: 400 !important;
        margin-left: 6px;
        color: $filter-algolia-title-counter !important;
      }
    }
  }
}

.active_filters {
  background-color: $background-filters-treez-modal;
  border-bottom: 1px solid $filter-algolia-divider;
  padding-bottom: 1.5rem;

  @include media(sm) {
    position: sticky;
    top: 0;
    z-index: 22;
  }

  .active_filters_container {
    display: flex;
    column-gap: 1rem;
    row-gap: 0.72rem;
    padding-right: 10px;
    flex-wrap: wrap;

    @include media(sm) {
      max-height: 140px;
      overflow-y: auto;
    }

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
      border-radius: 8px;
    }

    &:hover {
      &::-webkit-scrollbar-thumb {
        background-color: $gray-200;
      }
    }

    .filter_container {
      width: fit-content;
      height: 100%;
    }

    .filter_title {
      font-size: $text-xs;
      line-height: 1rem;
      font-weight: 400;
      text-transform: capitalize;
      letter-spacing: 0.01px;
      color: $gray-300 !important;
      margin-bottom: 6px;
    }

    .filter_list {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
    }
    
    .filter_pill {
      display: flex;
      align-items: center;
      background: $filter-pill-background;
      border-radius: $border-radius-md;
      border: 0.5px $filter-pill-border-type $filter-pill-border-color;
      padding: 4px 6px;
      color: $filter-pill-text-color;
      font-size: 0.625rem;
      text-transform: uppercase;
      height: fit-content;
      text-wrap: wrap;
      text-align: left;

      .pill_icon {
        font-size: 0.625rem;
        margin-left: 4px;
      }
    }
  }
}
