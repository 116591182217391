@use "sass:math";
@import '../../styles/variables';
@import '../../styles/mixin';

.filters {
  display: flex;
  margin-top: 25px;
  margin-right: -16px;

  .filters__container_main {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;

    @include media(lg) {
      flex-direction: row;
      gap: 0px;
    }
  }

  .filters__title {
    font-size: $text-base;
    font-weight: 400;
    margin-bottom: 0;
    display: flex;
    align-items: center;
  }

  .filters__container {
    display: flex;
    flex-grow: 1;
    overflow: auto hidden;
    scroll-behavior: smooth;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    &::before {
      background: linear-gradient(to right, $white 50%, rgba($white, 0));
      right: auto;
      left: 0;
    }

    &::after {
      content: '';
      position: -webkit-sticky;
      pointer-events: none;
      position: sticky;
      top: 0;
      right: 0;
      display: block;
      background: linear-gradient(to left, $white 50%, rgba($white, 0));
      flex: 0 0 16px;
    }

    @include media(lg) {
      &::before {
        content: '';
        position: -webkit-sticky;
        pointer-events: none;
        position: sticky;
        top: 0;
        right: 0;
        display: block;
        background: linear-gradient(to left, $white 50%, rgba($white, 0));
        flex: 0 0 16px;
      }
    }
  }

  .filters__container_all-product {
    display: none;

    @include media(lg) {
      display: flex;
      margin-right: 20px;
      align-items: center;

      button {
        width: 140px;
      }
    }
  }

  .filters__container_sorted-by {
    display: none;
    align-self: center;
    text-align: center;

    @include media(lg) {
      display: block;
      min-width: 90px;
    }
  }

  .filters__item-panel-title {
    font-size: $text-base;
    margin-bottom: 16px;
  }

  .filters__item {
    flex-shrink: 0;

    + .filters__item {
      margin-left: 8px;
    }

    &.filters__item-categories {
      button {
        color: $gray-600;
        box-shadow: none;

        &:hover {
          color: $gray-400;
          background-color: transparent;
        }
      }

      @include media(md) {
        transition: $transition-all;
      }

      &.filters__item_top {
        margin-right: -8px;

        .filters__item-categories-button {
          width: 0;
          opacity: 0;
        }
      }

      .filters__item-categories-button {
        width: 127px;
        padding-left: 0;
        padding-right: 0;
        overflow: hidden;
        transition: background-color 0.25s ease-in;

        @include media(md) {
          transition: $transition-all;
        }
      }

      .filters__item-panel {
        position: absolute;
        left: 50%;
        transform: translateX(calc(-50% - 16px));
        margin: 5px 16px 16px;
        max-width: 400px;
        width: calc(100% - 32px);

        @include media(sm) {
          width: 400px;
          left: 0;
          transform: none;
        }

        .heading.heading_level-4 {
          font-weight: 600;
          font-size: $text-sm;
          margin-bottom: 25px;
        }
      }

      .filters__item-panel-content {
        display: grid;
        margin-bottom: 0;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        row-gap: 8px;
        column-gap: 8px;

        .heading.heading_level-4 {
          grid-column: 1 / -1;
        }

        .category {
          display: grid;
          grid-template-rows: 132px auto;
        }

        .category__icon {
          width: 100%;
          height: 100%;
          background: $white;
          border-radius: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          box-shadow: $box-shadow;
        }

        .category__label {
          font-size: $text-sm;
          font-weight: 500;
          display: block;
          margin-top: 7px;
          letter-spacing: 0.2px;
          text-align: left;
        }
      }
    }

    > .button .icon:last-child {
      margin-right: 0 !important;
      margin-left: 4px;
    }
  }

  .filters__item-button {
    border: 1px solid $primary-100;
    border-radius: $border-radius-pill;
    color: $black;
    font-size: $text-base;
    font-weight: 400;
    letter-spacing: 0.5px;
    padding: 4px 12px 4px 16px;
    background: transparent;
    flex-wrap: wrap;
    align-content: center;
    height: 32px;
    width: 104px;

    &.filters__item-button_active {
      border-color: $black;
    }

    &:focus {
      outline: 0;
    }

    &:hover {
      border-color: $black;
      color: $black;
    }

    span {
      display: flex;
      justify-content: space-between;
    }
  }

  .filters__item-button-clear {
    font-size: $text-base;
    background: $primary;
    color: $white;
    border-radius: 16px;
    padding: 4px 16px;
    margin-left: 8px;
    height: 32px;
    min-width: 67px;
    text-align: center;

    &:hover {
      background: $primary;
      color: $white;
    }
  }

  .filters__item-panel {
    $form-width: 360px;
    $space: 16px;

    border-radius: $border-radius;
    max-width: $form-width;
    padding: $space;
    background: $white;
    z-index: 20;
    width: calc(100% - #{$space * 2});
    box-shadow: -4px 4px 20px rgba(0, 0, 0, 0.08);

    @include media(sm) {
      width: $form-width;
    }

    .filters__item-panel-footer {
      display: flex;
      justify-content: flex-end;
      margin-top: 16px;

      > * + * {
        margin-left: 16px;
      }

      .button {
        font-size: $text-base;
        padding: 8px 15px;
        font-weight: 400;
        box-shadow: none;

        &:last-child {
          color: $white;
        }
      }
    }
  }

  .filters__slider {
    $size: 28px;

    margin-top: 11px;
    padding-bottom: 24px;
    height: #{$size + 24};

    .filters__slider-thumb {
      width: $size;
      height: $size;
      border-radius: $border-radius-full;
      background: $primary;
    }

    .filters__slider-track {
      height: 2px;
      background: $gray-300;
      top: #{math.div($size, 2) - 1};

      &.filters__slider-track-1 {
        background: $gray-300;
      }
    }

    .filters__slider-mark {
      width: 8px;
      height: 8px;
      font-weight: 700;
      font-size: $text-sm;
      display: flex;
      justify-content: center;
      background: $primary;
      transform: translate(10px, -3px);
      border-radius: $border-radius-full;
      top: #{math.div($size, 2) - 1};

      span {
        color: $gray-200;
        margin-top: 27px;
      }

      &.filters__slider-mark_active {
        background: $primary;

        span {
          background: $primary;
        }
      }
    }
  }

  .filters__price {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 16px;
  }

  .filters__price_button {
    letter-spacing: 2px;
    box-shadow: none;
    width: 70px;
    padding: 10px;
    display: block;

    &[aria-checked='true'] {
      background: $primary;
      color: $white;
    }

    &[aria-checked='false'] {
      background: $gray-100;
      border: 1px solid $primary;
      color: $primary;
    }

    span {
      display: block;
      line-height: 1.2;
    }

    small {
      display: block;
      font-weight: 300;
    }
  }

  .filters__list-container-label {
    margin-bottom: 16px;
    color: $black;
  }

  .filters__list {
    button {
      color: $primary;

      &:first-child {
        padding-left: 0;
      }

      + button {
        margin-left: 4px;

        @include media(sm) {
          margin-left: 8px;
        }
      }
    }

    .filters__list-container {
      overflow-y: auto;
      max-height: 260px;
      margin: 16px -16px 0 -1px;
      padding-left: 1px;
      padding-right: 16px;

      input + input {
        margin-top: 16px;
      }
    }
  }

  .filters__list-search {
    margin-top: 10px;
  }
}
