@import '../../../styles/variables';
@import '../../../styles/mixin';

.filters_list {
  display: flex;
  flex-direction: column;
  // align-items: flex-start;

  .search_box {
    position: relative;
    display: flex;
    flex-grow: 1;
    margin: 8px 0;
    width: 100%;
    align-items: center;

    &__input {
      flex-grow: 1;
      border-radius: $filter-input-border-radius !important;
      border: 1px solid $filter-input-border-color !important;
      background-color: $filter-input-background !important;
      border-right: 0px;
      padding: 11px 48px 11px 0px;
      font-size: $text-base !important;

      &:hover {
        border: 1px solid $filter-input-border-color-activate !important;
      }

      &:focus-within {
        border: 1px solid $filter-input-border-color-activate !important;

        .input__label {
          color: $filter-input-border-color !important;
        }

        input::placeholder {
          color: $filter-input-placeholder-color !important;
        }
      }

      input[type='search'] {
        color: $filter-input-text-color !important;
      }

      input {
        .input__control {
          padding: 10px 54px 10px;
        }
      }
    }

    input::-webkit-search-cancel-button {
      -webkit-appearance: none;
      -webkit-user-modify: read-write !important;
      border-left: 1px solid $filter-input-separator-color !important;
      content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3 3L13 13M13 3L3 13' stroke='%23" + $filter-input-clear-color + "' stroke-width='1.6' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E") !important;
    }

    .search_box__button {
      position: absolute;
      right: 4px;
      border-radius: calc($border-radius-input - 2px);
      padding: 0 !important;
      height: 40px !important;
      width: 40px !important;
      color: $filter-button-icon-color;
      background: $filter-button-search-color;
      border: 1px solid $filter-button-search-color;

      &:not(:disabled):hover {
        color: $filter-button-icon-hover-color;
        background: $filter-button-search-hover-color;
        border: 1px solid $filter-button-search-hover-color;
      }

      &:not(:disabled):focus {
        color: $filter-button-icon-hover-color;
        background: $filter-button-search-hover-color;
        border: 1px solid $filter-button-search-hover-color;
      }
    }

    button {
      svg {
        * {
          stroke: $filter-button-icon-color !important;
        }
      }

      &:hover {
        svg {
          * {
            stroke: $filter-button-icon-hover-color !important;
          }
        }
      }
    }
  }

  .input_feedback {
    justify-content: center;
    height: 150px;
    align-self: center;
    align-content: center;
    display: flex;
    flex-direction: column;
    text-align: center;

    h5 {
      font-size: $text-sm;
    }

    p {
      font-size: $text-sm;
    }

    i {
      margin-bottom: 4px;
      font-size: $text-2xl;
    }
  }

  .show_more {
    margin-top: 0.5rem;
    color: $secondary;
    font-weight: 600;
    text-align: left;
  }

  &__with_scrollbar {
    max-height: 300px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $gray-200;
      border-radius: 8px;
    }
  }
}

.filters_list_item {
  padding: 0.35rem 0;
  display: flex;
  align-items: center;
  cursor: pointer;

  input[type='checkbox'] {
    position: relative;
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
    width: 1.15rem;
    height: 1.15rem;
    border: 2px solid $input-ckek-border-color;
    background-color: $input-ckek-background-color;
    border-radius: $border-radius-sm;

    &::before {
      content: '';
      position: absolute;
      left: 50%;
      top: 38%;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      display: none;
      transform: translate(-50%, -50%) rotate(45deg);
      height: 10px;
      width: 5px;
      border-bottom: 2px solid $input-check-select;
      border-right: 2px solid $input-check-select;
    }

    &:checked {
      border-color: $input-check-color;
      background: $input-ckek-background-select;

      &::before {
        display: inline-block;
      }
    }

    &:hover {
      cursor: pointer;
    }

    &:focus {
      border-color: $input-check-color;
    }

    &:disabled {
      background: $input-disabled-bg;
    }
  }

  .filters_list_item__label {
    max-width: 90%;
    margin-left: 0.5rem;
    text-transform: capitalize;
    color: $gray-600;
    font-size: $text-base;
    line-height: 24px;
  }

  .filters_list_item__count {
    margin-left: 0.5rem;
    background-color: $filter-algolia-bage;
    color: $secondary-400;
    padding: 0 0.5rem;
    border-radius: 20px;
    font-size: $text-xs;
    font-weight: 500;
  }
}
