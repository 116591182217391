@import '../../../styles/variables';
@import '../../../styles/mixin';

.filter__section_placeholder {
  display: none;

  @include media(md) {
    display: flex;
    width: 260px;
    flex-direction: column;
    overflow: hidden;
    row-gap: 16px;
    max-height: 790px;
  }

  .placeholder__dicount_badge {
    border-radius: 16px;
    height: 30px;
    width: 60%;
  }

  .placerholder__divider {
    width: 100%;
    height: 1px;
    background-color: $gray-50;
  }

  .placeholder__discount {
    height: 12px;
    width: 100%;
  }

  .placeholder__filter_container {
    .placeholder__filter_title {
      width: 40%;
      height: 20px;
      border-radius: 16px;
      background-color: $placeholder-bg-100;
    }

    .placeholder_refinementList__filters {
      display: flex;
      margin: 10px 0 24px 0;
      flex-direction: column;
      padding: 0 10px;
      row-gap: 16px;

      .placeholder_refinementList__filters_item {
        display: flex;
        gap: 10px;

        .placeholder__filter {
          height: 16px;
          width: 80px;
        }
      }

      .placeholder_refinementList__filters_item:nth-child(2) {
        .placeholder__filter {
          width: 150px;
        }
      }

      .placeholder_refinementList__filters_item:nth-child(3) {
        .placeholder__filter {
          width: 120px;
        }
      }
    }
  }

  .placeholder__filter_container:nth-last-child(1) {
    .placeholder_refinementList__filters_item:nth-child(1) {
      display: none;
    }

    .placerholder__divider {
      display: none;
    }
  }
}

.filter__section_mobile_placeholder {
  margin-top: 30px;
  display: block;
  width: 100%;

  .filter__mobile_placeholder {
    display: grid;
    grid-template-columns: minmax(0, 140px) minmax(0, 200px);
    grid-gap: 20px;
    justify-content: space-between;
    width: 100%;
    height: 30px;

    .placeholder {
      height: 22px;
      width: 100%;
      max-width: 140px;
      flex-grow: 1;

      + .placeholder {
        max-width: 200px;
      }
    }
  }

  @include media(md) {
    display: none;
  }
}
