@import '../../styles/variables';
@import '../../styles/mixin';

.figure {
  position: relative;
  width: 100%;
  overflow: hidden;
  background-color: $gray-50;

  img {
    height: auto;
  }

  svg {
    position: absolute;
    height: 22px;
    width: 22px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.cta__link {
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: 56px;
  transition: $transition-all;
  border-radius: $border-radius-btn;
  white-space: nowrap;
  font-size: $text-sm;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 500;
  letter-spacing: -0.01px;
  max-width: 388px;
  width: 100%;
  padding: 11px 30px;
  flex-grow: 1;
  color: $label-btn-primary;
  background: $btn-primary;
  border: 1px solid $border-btn-primary;

  @include media(sm) {
    max-width: 365px;
  }

  &:not(:disabled):hover {
    color: $label-btn-primary-hover;
    background: $btn-primary-hover;
    border: 1px solid $border-btn-primary-hover;
  }

  &:not(:disabled):focus {
    color: $label-btn-primary-pressed;
    background: $btn-primary-pressed;
    border: 1px solid $border-btn-primary-pressed;
  }

  &:disabled {
    color: $label-btn-primary-disabled;
    background: $btn-primary-disabled;
    border: 1px solid $border-btn-primary-disabled;
  }
}

.cta_section {
  position: relative;

  &__spacing_bottom {
    margin-bottom: $margin-section-mobile;

    @include media(lg) {
      margin-bottom: $margin-section;
    }
  }

  .cta_logo {
    max-width: 190px;
    height: 65px;

    object-fit: contain;
    @include media(lg) {
      max-width: 240px;
      height: 81px;
    }
  }
}

.full_width_container {
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
}

.centered_button_variation_container {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 40px;

  @include media(sm) {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  @include media(md) {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .cta__action_button {
    flex-grow: 1;
    max-width: 396px;
    align-content: center;

    @include media(sm) {
      max-width: 284px;
    }
  }
}

.default_variation_container {
  @media screen and (max-width: 640px) {
    padding-left: 0;
    padding-right: 0;
  }

  @include media(2xl) {
    margin: 0 auto;
  }

  .banner__vertical {
    @extend .figure;
    padding-top: 58.32%;
    display: block;

    @include media(sm) {
      display: none;
    }

    &_not_placeholder {
      background-color: transparent;
      svg {
        display: none;
      }
    }
  }

  .banner__horizontal {
    @extend .figure;
    display: none;

    @include media(sm) {
      padding-top: 20.42%;
      display: block;
    }

    &_not_placeholder {
      background-color: transparent;
      svg {
        display: none;
      }
    }
  }

  .cta__image_link {
    position: relative;
  }

  .cta__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.18) 18.79%,
      rgba(0, 0, 0, 0.32) 48.5%
    );
  }

  .info {
    position: absolute;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    justify-content: center;
    border-radius: $border-radius-md;
    padding: 12px;
    width: 100%;
    z-index: 3;
    top: 50%;

    &_align_left {
      left: 0;
      transform: translateY(-50%);
      text-align: left;
      align-items: flex-start;

      @include media(md) {
        left: 20px;
      }
    }

    &_align_right {
      right: 0;
      transform: translateY(-50%);
      text-align: right;
      align-items: flex-end;

      @include media(md) {
        right: 20px;
      }
    }

    &_align_center {
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      align-items: center;
    }

    &__text_mobile_left_aligned {
      align-items: flex-start;
      text-align: left;
    }

    @media screen and (min-width: 520px) {
      width: 90%;
    }

    @include media(sm) {
      row-gap: 12px;
    }

    @include media(md) {
      width: 70%;
    }

    @include media(xl) {
      row-gap: 16px;
    }

    @include media(2xl) {
      width: 45%;
      max-width: 756px;
      row-gap: 24px;
    }

    @include media(3xl) {
      max-width: 50%;
      row-gap: 30px;
    }

    .cta__action_button {
      display: none !important;

      @media screen and (min-width: 428px) {
        display: flex !important;
        flex-grow: 1;
        max-width: 388px;
        width: 100%;
      }
      @include media(sm) {
        display: none !important;
      }
      @include media(lg) {
        max-width: 365px;
        display: flex !important;
      }

      @include media(3xl) {
        margin-top: 16px;
        max-width: 365px;
        padding: 16px 30px;
      }
    }

    h1,
    h2 {
      font-weight: 700;
      font-size: 20px;

      @include media(sm) {
        font-size: 24px;
      }

      @include media(lg) {
        font-size: 26px;
        line-height: 28px;
      }

      @include media(xl) {
        font-size: 40px;
        line-height: 48px;
      }
    }

    p {
      width: 100%;
      font-size: $text-sm;

      @include media(sm) {
        font-size: $text-base;
      }
    }
  }
}

.cta__button {
  display: flex !important;
  flex-grow: 1;
  max-width: 388px;
  width: 100%;
  justify-content: center;
  align-items: center;

  @include media(md) {
    max-width: 365px;
  }
}

.flex_col_container {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  gap: 40px;
}

.image_two_cta_variation_container {
  @extend .flex_col_container;

  @include media(sm) {
    flex-direction: row;
    gap: 24px;
    justify-content: center;
    text-align: left;
  }

  @include media(2xl) {
    width: 80%;
    max-width: 1536px;
    margin: 0 auto;
  }

  .image_section {
    position: relative;
    max-width: 396px;
    height: auto;
    background-color: $gray-50;
    order: 1;

    @include media(sm) {
      max-width: 757px;
    }
  }

  .content_section {
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 24px;

    @media screen and (min-width: 428px) {
      align-self: flex-start;
    }

    @include media(sm) {
      gap: 52px;
      align-items: end;
      text-align: right;
      align-self: center;
    }

    h2 {
      font-size: 24px;

      @include media(xl) {
        font-size: 32px;
      }
    }

    .cta_section {
      display: flex;
      flex-direction: column;
      gap: 20px;
      max-width: 495px;
      align-items: start;

      @include media(lg) {
        flex-direction: row;
        align-items: end;
        text-align: right;
      }

      span {
        width: 190px !important;
        height: 64px !important;

        @include media(lg) {
          width: initial !important;
          height: initial !important;
        }
      }
    }
  }
}

.title_cta_variation_container {
  @extend .flex_col_container;

  @include media(sm) {
    gap: 0;
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
  }

  @include media(2xl) {
    width: 80%;
    max-width: 1536px;
    margin: 0 auto;
  }

  .title {
    max-width: 625px;

    h2 {
      font-size: 32px;

      @include media(xl) {
        font-size: 40px;
      }
    }
  }
}

.image_title_button_variation_container {
  .image_title_button_variation_body {
    display: flex;
    flex-direction: column;
    gap: 40px;

    @include media(xl) {
      flex-direction: row;
      align-items: center;
      gap: 24px;
    }

    .content_section {
      display: flex;
      flex-direction: column;
      text-align: left;
      gap: 24px;

      @include media(md) {
        max-width: 495px;
      }

      @include media(xl) {
        gap: 52px;
      }

      h2 {
        font-size: 24px;

        @include media(xl) {
          font-size: 32px;
        }
      }
    }

    .image_container {
      width: 100%;
      max-width: 396px;

      @include media(sm) {
        max-width: 757px;
      }

      .image_wrapper {
        position: relative;
        width: 100%;
        padding-top: 113%;
        overflow: hidden;

        @include media(sm) {
          padding-top: 52.3%;
        }
      }
    }
  }
}

.title_two_link_variation_container {
  @include media(2xl) {
    width: 80%;
    max-width: 1536px;
    margin: 0 auto;
  }

  .title_two_link_variation_body {
    @extend .flex_col_container;
    max-width: 1144px;
    gap: 40px;

    @include media(lg) {
      justify-content: space-between;
      text-align: left;
      flex-direction: row;
    }

    @include media(3xl) {
      max-width: 75%;
    }

    .info {
      text-align: center;
      max-width: 495px;
      display: flex;
      flex-direction: column;
      gap: 16px;

      h2 {
        font-size: 24px;

        @include media(xl) {
          font-size: 32px;
        }
      }

      @include media(lg) {
        text-align: left;
      }

      @include media(2xl) {
        max-width: 43.26%;
      }
    }

    .cta_section {
      display: flex;
      flex-direction: column;
      gap: 8px;
      flex-shrink: 0;
      max-width: 495px;

      @media screen and (min-width: 420px) {
        flex-direction: row;
      }

      @include media(sm) {
        flex-direction: row;
        column-gap: 24px;
      }

      @include media(2xl) {
        max-width: 43.26%;
      }
    }
  }
}

.image_logo_button_variation_container {
  @media screen and (max-width: 640px) {
    padding-left: 0;
    padding-right: 0;
  }

  @include media(2xl) {
    margin: 0 auto;
  }

  .banner__vertical {
    @extend .figure;
    padding-top: 58.32%;
    display: block;

    @include media(sm) {
      display: none;
    }

    &_not_placeholder {
      background-color: transparent;
      svg {
        display: none;
      }
    }
  }

  .banner__horizontal {
    @extend .figure;
    display: none;

    @include media(sm) {
      padding-top: 22%;
      display: block;
    }

    &_not_placeholder {
      background-color: transparent;
      svg {
        display: none;
      }
    }
  }

  .cta__image_link {
    position: relative;
  }

  .cta__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.18) 18.79%,
      rgba(0, 0, 0, 0.32) 48.5%
    );
  }

  .info {
    position: absolute;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    justify-content: center;
    border-radius: $border-radius-md;
    width: 100%;
    z-index: 3;
    top: 50%;

    .logo_container {
      width: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      @media screen and (max-width: 750px) {
        max-width: 60px;
      }
    }

    &_align_left {
      left: 0;
      transform: translateY(-50%);
      text-align: left;
      align-items: flex-start;

      @include media(md) {
        left: 20px;
      }
    }

    &_align_right {
      right: 0;
      transform: translateY(-50%);
      text-align: right;
      align-items: flex-end;

      @include media(md) {
        right: 20px;
      }
    }

    &_align_center {
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      align-items: center;
    }

    &__text_mobile_left_aligned {
      align-items: flex-start;
      text-align: left;
    }

    @media screen and (min-width: 520px) {
      width: 100%;
    }

    @include media(sm) {
      row-gap: 12px;
    }

    @include media(md) {
      width: 70%;
    }

    @include media(xl) {
      row-gap: 16px;
    }

    @include media(2xl) {
      width: 45%;

      max-width: 756px;
      row-gap: 24px;
    }

    @include media(3xl) {
      max-width: 50%;
      row-gap: 30px;
    }

    .cta__action_button {
      display: none !important;

      @include media(sm) {
        display: none !important;
      }

      @include media(xl) {
        max-width: 365px;
        display: flex !important;
      }

      @include media(3xl) {
        margin-top: 16px;
        max-width: 365px;
        padding: 16px 30px;
      }
    }

    h1,
    h2 {
      font-weight: 700;
      font-size: 18px;

      @include media(sm) {
        font-size: 24px;
      }

      @include media(lg) {
        font-size: 26px;
        line-height: 28px;
      }

      @include media(xl) {
        font-size: 40px;
        line-height: 48px;
      }
    }

    p {
      width: 100%;
      font-size: $text-base;
    }
  }
}
