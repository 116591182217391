@import '../../styles/variables';
@import '../../styles/mixin';

.layout__flex_column {
  display: flex;
  flex-direction: column;
}

.layout__flex_row {
  display: flex;
  flex-direction: row;
}

.faqs {
  &__section {
    display: flex;
    align-items: center;
    width: 100%;

    p, h5, h6 {
      padding: 4px 15px 4px 10px;
      line-height: 20px;
      font-size: $text-sm !important;

      @include media(sm) {
        padding: 6px 15px 6px 10px;
        font-size: $text-base !important;
        line-height: 24px;
      }
    }

    h3, h4 {
      padding: 5px 15px 5px 10px;
      font-size: $text-base !important;
      line-height: 20px !important;
      letter-spacing: -0.02px;
      font-weight: 500 !important;
      
      @include media(sm) {
        padding: 10px 15px 10px 10px;
        font-size: $text-lg !important;
        line-height: 24px;
      }
    }

    .faqs__header {
      @extend .layout__flex_column;
      width: 100%;
      row-gap: 12px;
    }

    .faqs__section_element {
      position: relative;
      color: $gray-400;
      font-size: $text-base;
      padding-top: 10px;
      padding-bottom: 10px;
      cursor: pointer;
      border-bottom: 0.5px solid $gray-200;

      &_effect {
        &:hover {
          .accordion__title {
            padding-left: 4px;
            transition-property: all;
            transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            transition-duration: 0.15s;
          }
        }
      }
    }

    .accordion__title {
      display: grid;
      grid-template-columns: 1fr auto;
      align-items: center;
      font-size: $text-xl;
      font-weight: 700;
      line-height: 28px;
      color: $gray-500;
      letter-spacing: -0.02px;

      .icon {
        font-size: $text-xl;
        margin-left: 13px;
      }

      p {
        line-height: 24px;
        font-size: $text-base;
        letter-spacing: -0.02px;

        @include media(sm) {
          font-size: $text-xl;
          line-height: 28px;
        }
      }
    }
  }

  &__container {
    @extend .layout__flex_row;
    flex-direction: column;
    align-items: center;
    row-gap: 68px;
    width: 100%;

    &_default {
      row-gap: 36px;
    }

    &_spacing {
      padding-right: 20px;
      padding-left: 20px;

      @include media(sm) {
        padding-right: 40px;
        padding-left: 40px;
      }

      @include media(xl) {
        padding-right: 90px;
        padding-left: 90px;
        column-gap: 90px;
      }

      @include media(2xl) {
        padding-right: 107px;
        padding-left: 107px;
        column-gap: 107px;
      }

      @include media(3xl) {
        padding-right: 144px;
        padding-left: 144px;
        column-gap: 144px;
      }
    }
  }

  &__body {
    display: grid;
    column-gap: 32px;
    width: 100%;

    &_columns {
      @include media(xl) {
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
      }
    }

    &_info {
      row-gap: 32px;
      figure {
        position: relative;
        align-self: flex-start;
        width: 100%;
        padding-top: 64%;
        overflow: hidden;
        background-color: $gray-50;
        border-radius: $border-radius;
        order: 0;

        @include media(xl) {
          padding-top: 83%;
        }

        img {
          height: auto;
          border-radius: $border-radius;
        }

        svg {
          position: absolute;
          height: 30px;
          width: 30px;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }

        &:hover,
        &:focus-within {
          img,
          svg {
            transition: all 0.3s ease-in;
            opacity: 0.8;
          }
        }
      }

      .faqs__header {
        max-width: 640px !important;
        order: 0;
      }

      .faqs__list {
        order: 1;
      }

      @include media(xl) {
        grid-template-columns: 40% minmax(0, 1fr);
        column-gap: 86px;
      }
    }

    &_reverse {
      @include media(xl) {
        grid-template-columns: minmax(0, 1fr) 40%;

        .faqs__header,
        figure {
          order: 1;
        }

        .faqs__list {
          order: 0;
        }
      }
    }

    &_header_center {
      .faqs__header {
        margin: auto 0;
      }
    }
  }

  &__list {
    flex-grow: 1;
  }
}
