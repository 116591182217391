@import '../../styles/variables';
@import '../../styles/mixin';

.date-picker {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  padding: 0 0.25rem;

  .date-picker__label {
    color: $primary;
  }

  .date-picker__content {
    display: flex;
    justify-content: space-between;
    column-gap: 1rem;

    .date-picker__input {
      flex: 1;

      .date-picker__menu {
        height: auto;

        > div {
          max-height: 180px;
        }

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }
}
