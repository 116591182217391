@import '../../styles/variables';
@import '../../styles/mixin';

.accordion_header {
  p {
    padding: 5px 0 !important;
  }
}

.actions {
  background: $white;
  bottom: -1px;
  border-top: 0.5px solid $gray-300;
  gap: 20px;
  display: flex;
  padding: 20px 0;
  position: sticky;

  button {
    padding: 8px 11px;
    height: 40px;
    text-transform: uppercase;
    width: 100%;
  }
  button:first-child {
    border-color: $gray-300;
  }
}
